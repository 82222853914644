import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

const theme = createMuiTheme();
const useStyles = makeStyles((theme) => ({
  parallax: {
    width: "100%",
    marginTop: "0px",
  },
  filter: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.2)",
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
    },
  },
  small: {
    height: "380px",
  },
}));
const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function HeaderSlider(props) {
  let windowScrollTop;
  if (window.innerWidth >= 768) {
    windowScrollTop = window.pageYOffset / 3;
  } else {
    windowScrollTop = 0;
  }
  const [transform, setTransform] = React.useState(
    "translate3d(0," + windowScrollTop + "px,0)"
  );
  React.useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener("scroll", resetTransform);
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener("scroll", resetTransform);
      }
    };
  });
  const resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    setTransform("translate3d(0," + windowScrollTop + "px,0)");
  };
  const { filter, className, images, small } = props;
  const classes = useStyles();
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes.filter]: filter,
    [classes.small]: small,
    [className]: className !== undefined,
  });

  return (
    <div className={parallaxClasses} style={{ transform }}>
      {images.length !== 0 ? (
        <AutoplaySlider
          play={true}
          cancelOnInteraction={false}
          interval={6000}
          organicArrows={false}
          fillParrent={true}
          bullets={false}
        >
          {images.map((x, i) => (
            <div key={i} data-src={x.address} />
          ))}
        </AutoplaySlider>
      ) : (
        <div className={parallaxClasses} style={{ backgroundColor: "#6e706f" }}>
          <AutoplaySlider
            play={true}
            cancelOnInteraction={false}
            interval={6000}
            organicArrows={false}
            fillParrent={true}
            bullets={false}
          >
            {images.map((x, i) => (
              <div key={i} data-src={x.address} />
            ))}
          </AutoplaySlider>
        </div>
      )}
    </div>
  );
}

HeaderSlider.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.string,
  image: PropTypes.string,
  small: PropTypes.bool,
};

// i !== 0 ? i : 0
