import React, { useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Carousel from "../../components/Main Website/Carousel/Carousel";
import Section from "../../components/Main Website/Section/Section";
import SmoothScrollTop from "../../utils/SmoothScrollToTop";
//Actions
import {
  getAllTextAndHeaderPublic,
  getSantourHouse,
  landingAndSantourHeader,
  getAllTitlesPublic,
} from "../../actions/adminActions";
import { getAllManagersPublic } from "../../actions/managerActions";
import GridContainer from "../../components/Main Website/Grid/GridContainer";
import GridItem from "../../components/Main Website/Grid/GridItem";
import HeaderSlider from "../../components/Main Website/Parallax/HeaderSlider";
import Loading from "../../components/common/Loading";
import styles from "../../assets/jss/views/landingPage";
import NotFound from "../../components/common/NotFound";
import { Typography } from "@material-ui/core";
import parse from "html-react-parser";

const useStyles = makeStyles(styles);

function SantourHouse() {
  const [loading, setLoading] = React.useState(true);
  // const [address, setAddress] = React.useState("");
  // const [open, setOpen] = React.useState(false);
  const [headerImages, setHeaderImages] = React.useState([]);
  const [description, setDescription] = React.useState("");
  const [manager, setManager] = React.useState(null);
  const [sahami, setSahami] = React.useState(null);
  const [students, setStudents] = React.useState(null);
  const [notes, setNotes] = React.useState(null);
  const [titles, setTitles] = React.useState([]);

  const auth = useSelector((state) => state.auth);
  const managers = useSelector((state) => state.managers);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    SmoothScrollTop();
    dispatch(getAllTextAndHeaderPublic());
    dispatch(getAllTitlesPublic());
    dispatch(landingAndSantourHeader({ tag: "خانه سنتور" }));
    dispatch(getAllManagersPublic());
    dispatch(getSantourHouse());
  }, []);

  useEffect(() => {
    if (managers.managers.length !== 0) {
      const santourHouseManager = managers.managers.filter(
        (manager) => manager.position === "Institute Manager"
      )[0];
      setManager(santourHouseManager);
    }
  }, [managers]);

  useEffect(() => {
    if (auth.sliderImages) {
      const textHeader = auth.sliderImages;
      setHeaderImages(textHeader.pictures.slice());
      setDescription(textHeader.description);
      auth.sliderImages = null;
    }

    if (auth.santourHouse) {
      let santourHouse = auth.santourHouse;
      auth.santourHouse = null;
      santourHouse.sahami ? setSahami(santourHouse.sahami) : setSahami(null);
      santourHouse.topStudents
        ? setStudents(santourHouse.topStudents)
        : setStudents(null);
      santourHouse.notes ? setNotes(santourHouse.notes) : setNotes(null);

      setLoading(false);
    }

    if (auth.titles) {
      setTitles(auth.titles.slice());
      auth.titles = null;
    }
  }, [auth]);

  useEffect(() => {
    if (errors.errors) {
      let err = "";
      if (typeof errors.errors.data !== "undefined") {
        err =
          typeof errors.errors.data.error !== "undefined"
            ? errors.errors.data.error
            : errors.errors.data;

        if (err === "فاقد اطلاعات") {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }

      errors.errors = null;
    }
  }, [errors]);

  const classes = useStyles();

  const content = (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            {titles.filter((t) => t.tag === "تیتر اصلی صفحه خانه سنتور")
              .length !== 0 && (
              <Typography variant="h4" className={classes.title}>
                {
                  titles.filter((t) => t.tag === "تیتر اصلی صفحه خانه سنتور")[0]
                    .title
                }
              </Typography>
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={7} data-aos="zoom-in-up">
            <div className={classes.paragraph}>{parse(`${description}`)}</div>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.section}>
        <GridContainer justify="flex-start">
          {!loading && sahami && students && notes && manager && (
            <>
              <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
                <Carousel arrows={true}>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}
                  >
                    <Section
                      title={
                        titles.filter(
                          (t) => t.tag === "تیتر بخش مدیریت خانه سنتور"
                        ).length !== 0
                          ? titles.filter(
                              (t) => t.tag === "تیتر بخش مدیریت خانه سنتور"
                            )[0].title
                          : "مدیریت خانه سنتور"
                      }
                      link="/مدیر آموزشگاه"
                      description={manager.snippet}
                      src={manager.picture.address}
                      // onClick={() => {
                      //   setAddress(manager.picture.address);
                      //   setOpen(true);
                      // }}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}
                  >
                    <Section
                      title={
                        titles.filter(
                          (t) => t.tag === "تیتر بخش آثار استاد سهامی"
                        ).length !== 0
                          ? titles.filter(
                              (t) => t.tag === "تیتر بخش آثار استاد سهامی"
                            )[0].title
                          : "آثار استاد سهامی"
                      }
                      link="/آثار-استاد-سهامی"
                      description={sahami.description}
                      src={sahami.poster.address}
                      // onClick={() => {
                      //   setAddress(sahami.poster.address);
                      //   setOpen(true);
                      // }}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}
                  >
                    <Section
                      title={
                        titles.filter(
                          (t) => t.tag === "تیتر بخش نت‌های منتشر شده"
                        ).length !== 0
                          ? titles.filter(
                              (t) => t.tag === "تیتر بخش نت‌های منتشر شده"
                            )[0].title
                          : "نت‌های منتشر شده"
                      }
                      link="/نت ها"
                      description={notes.description}
                      src={notes.poster.address}
                      // onClick={() => {
                      //   setAddress(notes.poster.address);
                      //   setOpen(true);
                      // }}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.itemGrid}
                  >
                    <Section
                      title={
                        titles.filter((t) => t.tag === "تیتر بخش آثار هنرجویان")
                          .length !== 0
                          ? titles.filter(
                              (t) => t.tag === "تیتر بخش آثار هنرجویان"
                            )[0].title
                          : "آثار هنرجویان خانه سنتور"
                      }
                      link="/آثار-هنرجویان-برتر"
                      description={students.description}
                      src={students.poster.address}
                      // onClick={() => {
                      //   setAddress(students.poster.address);
                      //   setOpen(true);
                      // }}
                    />
                  </GridItem>
                </Carousel>
              </GridItem>
            </>
          )}
        </GridContainer>
      </div>
    </>
  );

  const _loading = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loading color="primary" />
    </div>
  );

  let notFound = (
    <>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} data-aos="fade-down">
            {titles.filter((t) => t.tag === "تیتر اصلی صفحه خانه سنتور")
              .length !== 0 && (
              <Typography variant="h4" className={classes.title}>
                {
                  titles.filter((t) => t.tag === "تیتر اصلی صفحه خانه سنتور")[0]
                    .title
                }
              </Typography>
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={8} data-aos="zoom-in-up">
            <div className={classes.paragraph}>{parse(`${description}`)}</div>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer justify="flex-start">
        <GridItem xs={12} sm={12} md={12} data-aos="zoom-in-up">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            className={classes.section}
          >
            <NotFound />
          </div>
        </GridItem>
      </GridContainer>
    </>
  );

  return (
    <div>
      <HeaderSlider images={headerImages} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {loading
            ? _loading
            : !sahami || !notes || !students || !manager
            ? notFound
            : content}
        </div>
      </div>
    </div>
  );
}

export default SantourHouse;
