import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import GridItem from "../Grid/GridItem";
import React from "react";
import ZoomImage from "../../../shared/components/ZoomImage";
import classNames from "classnames";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import styles from "../../../assets/jss/views/landingPageSections/teamStyle";

const useStyles = makeStyles(styles);

export default function Section(props) {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid2,
    classes.ingCardTop
  );

  const { src, title, description, link } = props;

  return (
    <div>
      <Card plain>
        <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
          <ZoomImage
            alt=""
            src={`${src}`}
            className={imageClasses}
            gallery={true}
          />
        </GridItem>
        <h4
          className={classes.cardTitle}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {title}
          <br />
        </h4>
        <CardBody>
          <div className={classes.description}>
            {parse(`${description}`)}
            <Link
              to={`${link}`}
              style={{ textDecoration: "none" }}
              className={classes.cardTitle}
            >
              <Button color="primary">ادامه مطلب...</Button>
            </Link>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
