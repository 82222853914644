import React from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  CssBaseline,
} from "@material-ui/core";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import store from "./store";
//import MainPage from "./pages/MainPage";
import Footer from "./components/Main Website/Footer/Footer";
import InstituteManager from "./pages/Management/InstituteManager";
import SantourHouse from "./pages/SantourHouse/SantourHouse";
import SahamiWorks from "./pages/SantourHouse/SahamiWorks";
import StudentsWorks from "./pages/SantourHouse/StudentsWorks";
import Notes from "./pages/SantourHouse/Notes";
import "react-multi-carousel/lib/styles.css";
import "./App.css";
import AOS from "aos";
AOS.init({
  disable: false,
  startEvent: "DOMContentLoaded",
  initClassName: "aos-init",
  animatedClassName: "aos-animate",
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,
  offset: 200,
  delay: 50,
  duration: 1000,
  easing: "ease",
  once: true,
  mirror: false,
  anchorPlacement: "top-bottom",
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
  },
  typography: {
    fontFamily: "Vazir",
  },
  shape: {
    borderRadius: 10,
  },
});

function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            {/* <Route>
              <MainPage />
            </Route> */}
            <Route exact path="/مدیر آموزشگاه" component={InstituteManager} />
            <Route exact path="/" component={SantourHouse} />
            <Route exact path="/آثار-استاد-سهامی" component={SahamiWorks} />
            <Route exact path="/آثار-هنرجویان-برتر" component={StudentsWorks} />
            <Route exact path="/نت ها" component={Notes} />
          </Switch>
          <Footer />
        </BrowserRouter>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;
