import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ScaleLoader } from "react-spinners";

const useStyles = makeStyles({
  root: {
    display: "block",
    margin: "auto auto",
    borderColor: "red",
    justifySelf: "center",
  },
});

export default function CircularIndeterminate(props) {
  const classes = useStyles();
  const { login } = props;
  const loginPage = (
    <div style={{ marginTop: "32px", marginBottom: "32px" }}>
      <ScaleLoader
        className={classes.root}
        size={50}
        color="#0f8ebd"
        loading={true}
      />
    </div>
  );
  const other = (
    <div style={{ marginTop: "256px", marginBottom: "256px" }}>
      <ScaleLoader
        className={classes.root}
        size={50}
        color="#0f8ebd"
        loading={true}
      />
    </div>
  );
  return <> {login ? loginPage : other}</>;
}
